@use 'angular-material-css-vars/public-util' as mat-css-utilities;
@import 'theme';

html, body {
  height: 100%;
}

body {
  margin: 0;
}

body, p {
  font-size: 14px;
}

button {
  font-weight: 700 !important;
  text-transform: uppercase;
}

.error-text {
  color:  mat-css-utilities.mat-css-color-warn(500) !important;
}

.accent-text-color {
  color:  mat-css-utilities.mat-css-color-accent(500) !important;
}

.padding-20 {
  padding: 20px;
}

app-root {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.divider {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  margin: 10px 0
}

[hidden] {
  display: none !important;
}

.small-circular {
  min-width: 75px;
  max-width: 75px;
  height: 75px;
  border-radius: 75px;
  -webkit-border-radius: 75px;
  -moz-border-radius: 75px;
}

.no-padding-dialog .mat-dialog-container {
  padding: 0;

  .mat-dialog-content {
    margin: 0;
    padding: 0;
  }
}

.no-scroll-dialog .mat-dialog-container {
  overflow-x: hidden;
}

.grey-icon {
  color: rgba(0, 0, 0, 0.54);
}

.padding-10 {
  padding: 10px;
}

.padding-10-not-bottom {
  padding: 10px 10px 0 10px;
}

.no-padding {
  padding: 0 !important;
}

.no-margin-dialog .mat-dialog-content {
  margin: 0;
}

.no-margin {
  margin: 0 !important;
}

.margin-10 {
  margin: 10px;
}

.margin-v-20 {
  margin: 20px 0;
}

.help-card-long {
  margin: 0 !important;
  padding: 0 !important;
  height: 430px !important;
}

.full-width-form-item {
  width: 100%;
}

.content-text {
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}

.item-title {
  min-width: 150px;
  font-size: 20px;
  font-weight: 600;
  margin: 0 !important;
  color: rgba(0, 0, 0, 0.72)
}

.dropZoneColor {
  border: 3px dotted rgba(0, 0, 0, 0.08);
  background-color: rgba(0, 0, 0, 0.12)
}

.dropZoneColorDrag {
  border: 3px dotted rgba(0, 0, 0, 0.28);
  background-color: grey
}

.dropZoneColorDrop {
  border: 3px dotted rgba(0, 0, 0, 0.08);
  background-color: rgba(0, 0, 0, 0.12)
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 100;
  right: 0;
  flex-grow: 1;
}

.white-text {
  color: white;
}

@media #{$mat-lt-lg} {
  .sticky {
    width: 100%;
  }
}

.pet-photo-multiple {
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-color:  mat-css-utilities.mat-css-color-accent(500);
  border-style: solid;
}

@media #{$mat-xs} {
  .centered-card {
    max-width: 300px;
  }

  .circular {
    min-width: 75px;
    max-width: 75px;
    height: 75px;
    border-radius: 75px;
    -webkit-border-radius: 75px;
    -moz-border-radius: 75px;
  }
  .padding-10-not-bottom {
    padding: 10px 5px 0 5px;
  }
  .pet-photo {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-color:  mat-css-utilities.mat-css-color-accent(500);
    border-style: solid;
  }
  .padding {
    padding: 20px;
  }
  .help-card {
    margin: 0 !important;
    padding: 0 !important;;
  }
  .full-width-xs {
    width: 100%;
  }
  .mat-stepper-vertical .mat-vertical-content-container .mat-vertical-stepper-content .mat-vertical-content {
    padding: 0 0 0 10px;
  }

}

@media #{$mat-gt-xs} {
  .item-start {
    min-width: 150px;
  }

  .circular {
    min-width: 150px;
    max-width: 150px;
    height: 150px;
    border-radius: 150px;
    -webkit-border-radius: 150px;
    -moz-border-radius: 150px;
  }
  .centered-card {
    max-width: 600px;
  }
  .padding {
    padding: 50px;
  }

  .pet-photo {
    min-width: 80px;
    max-width: 80px;
    height: 80px;
    border-radius: 80px;
    -webkit-border-radius: 80px;
    -moz-border-radius: 80px;
    border-color:  mat-css-utilities.mat-css-color-accent(500);
    border-style: solid;
  }
  .help-card {
    margin: 0 !important;
    padding: 0 !important;
    height: 330px !important;
  }
}

.background-image {
  background-size: cover !important;
  background-position: center !important;
}

@media #{$mat-gt-md} {
  .sticky {
    left: 350px;
  }
}

@media #{$mat-gt-xl} {
  .help-card {
    margin: 0 !important;
    padding: 0 !important;
    height: 420px !important;
  }
}

#ccc .ccc-vendors .optional-vendor-heading {
  color: #3f51b5;
  font-size: 18px;
  margin: 20px 0 0 0;
  text-decoration: none;
}

#ccc .ccc-vendors .optional-vendor-content p {
  margin: 0;
}

#ccc .checkbox-toggle--slider .checkbox-toggle-label {
  padding: 0;
}

#ccc .ccc-notify-button.ccc-close-button span {
  font-weight: normal !important;
}

#ccc .ccc-notify-text,
#ccc .ccc-notify-buttons {
  transition: opacity 0.25s;
  opacity: 0;
}

#ccc #ccc-notify.ccc-notify__box {
  height: 258px !important;
  max-height: none !important;
  padding: 32px !important;

  @media screen and (max-width: 600px) {
    height: auto !important;
  }
}

#ccc #ccc-notify-title {
  width: 100%;
  margin-bottom: 16px;
}

#ccc .ccc-notify-button {
  margin-bottom: 0 !important;
}

#ccc .ccc-notify-button.ccc-reject-button {
  display: none;
}

#ccc #ccc-notify #ccc-notify-dismiss.ccc-notify-box-dismiss {
  display: none !important;
}

