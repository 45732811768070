@use '@angular/material' as mat;
@use 'angular-material-css-vars/main' as mat-css;
@use 'angular-material-css-vars/public-util' as mat-css-utilities;
@use '@angular/material/theming' as mat-theming;

$mat-xs: "screen and (max-width: 599px)";
$mat-sm: "screen and (min-width: 600px) and (max-width: 959px)";
$mat-md: "screen and (min-width: 960px) and (max-width: 1279px)";
$mat-lg: "screen and (min-width: 1280px) and (max-width: 1919px)";
$mat-xl: "screen and (min-width: 1920px) and (max-width: 5000px)";
$mat-lt-sm: "screen and (max-width: 599px)";
$mat-lt-md: "screen and (max-width: 959px)";
$mat-lt-lg: "screen and (max-width: 1279px)";
$mat-lt-xl: "screen and (max-width: 1919px)";
$mat-gt-xs: "screen and (min-width: 600px)";
$mat-gt-sm: "screen and (min-width: 960px)";
$mat-gt-md: "screen and (min-width: 1280px)";
$mat-gt-xl: "screen and (min-width: 1920px)";
// init theme
@include mat-css.init-material-css-vars($load-legacy-components: true, $load-mdc-components: false);
//@include mat-css-set-palette-defaults(mat.$indigo-palette, 'primary');
//@include mat-css-set-palette-defaults(mat.$pink-palette, 'accent');
//@include mat-css-set-palette-defaults(mat.$red-palette, 'warn');
@include mat-css-utilities.mat-css-set-palette-defaults(mat-theming.$mat-indigo, 'primary');
@include mat-css-utilities.mat-css-set-palette-defaults(mat-theming.$mat-pink, 'accent');
@include mat-css-utilities.mat-css-set-palette-defaults(mat-theming.$mat-red, 'warn');
.primary-background {
  background-color: mat-css-utilities.mat-css-color-primary(500);
}

.accent-text-color {
  color: mat-css-utilities.mat-css-color-accent(500);
}

.toolbar {
  background-color: white;
  color: mat-css-utilities.mat-css-color-primary(500);
  height: 60px;
}

.background-color {
  color: white;
  background-color: mat-css-utilities.mat-css-color-primary(500);
}

.card-header-text {
  color: white;
}

.login-background {
  background-color: mat-css-utilities.mat-css-color-primary(500);
}

@media #{$mat-xs} {
  .main-logo {
    min-height: 65px;
    width: 350px;
    margin: 5px;
  }
  .menu-logo {
    min-height: 55px;
    width: 200px;
  }
  .overlay-container {
    position: absolute;
    left: 0;
    top: 355px;
    height: 550px;
    width: 100%;
    overflow: hidden;
    transition: transform 0.4s ease-in-out;
    z-index: 100;
  }
  .main-padding {
    margin: 60px 10px;
  }
}


@media #{$mat-gt-xs} {
  .main-logo {
    min-height: 120px;
    width: 500px;
    margin-bottom: 30px;
  }
  .menu-logo {
    min-height: 55px;
    width: 350px;
  }
  .main-padding {
    margin: 60px 20px;
  }
}

$custom-typography: mat.define-legacy-typography-config(
  $font-family: 'Roboto',
  $headline: mat.define-typography-level(32px, 32px, 600),
  $button: mat.define-typography-level(18px, 18px, 400),
  $subheading-1: mat.define-typography-level(20px, 24px, 400),
  $body-1: mat.define-typography-level(18px, 18px, 300),
  $title: mat.define-typography-level(24px, 32px, 500),
  $body-2: mat.define-typography-level(16px, 16px, 300),
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();
